import React from "react"
import Layout from "@components/layout"
import { GatsbyImage } from "gatsby-plugin-image"

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// JSONファイル呼び出し
import blogText from "@content/locales/ja/campaign-archive.json";

// コンポーネント呼び出し
import { CampaignGraphqlArray } from "@components/graphql-data-campaign.js"; // 記事情報取得・形成
import { FixedFoot } from "@components/footer";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定（Title,description以外）

const CampaignArchive = ({data, pageContext}) => {
  const indexUrl = '/';
  const myUrl = 'campaign/'
  const name = pageContext.name; // 該当タグ名
  const myPageNum = pageContext.page;

  // Seo情報設定（Title,description以外）
  const pathName = 'tags';
  const seoTag = SeoContents({ Path:pathName, Title:name });
            
  // graphql-data-post.jsから記事データ呼び出し
  const setNum = 6; // 一覧の表示数
  const allPostData = CampaignGraphqlArray( '' , 'allmix');
  console.log(allPostData)

  // 本文設定（各カテゴリ、blog-archive.jsonで設定）
  const commonTitle = blogText[ "common-title" ];
  // const postTitle = blogText[ "common-title" ];
  // const postText1 = blogText[ "common-txt1" ];

  // 記事一覧生成設定
  let setArray, startNum, endNum;
  setArray = allPostData.allData; // 全データ
  let resultArray =[];
  startNum = (myPageNum - 1) * setNum; // 一覧の先頭番号
  endNum = myPageNum * setNum; // 一覧の最終番号
  endNum = (endNum > setArray.length) ? setArray.length : endNum; // 6個に足りていない場合の処理
  for (let i = startNum; i < endNum; i++){
    resultArray.push(setArray[i])
  }
  console.log(resultArray)
  const pickupList = resultArray.map((result) => {
    let shortName; // タイトル文字数制限設定
    const len = 35; // 制限文字数
    if ( result.Title.length > len ) {
      shortName = result.Title.substr( 0, len) + ' ...'; // 省略
    } else {
      shortName = result.Title;
    }
    let shortNameEx; // Description文字数制限設定
    const lenEx = 60; // 制限文字数
    if ( result.Description.length > lenEx ) {
      shortNameEx = result.Description.substr( 0, lenEx) + ' ...'; // 省略
    } else {
      shortNameEx = result.Description;
    }
    return  <>
              <div className="md:w-[31.33333%] w-full  mb-10   max-w-[350px] border-[1px] rounded-xl mx-[1%]" key={result.Uri} >
                <article className="post-list-item !m-0 h-[100%]"  itemScope itemType="http://schema.org/Article">
                  <a href={indexUrl + myUrl + result.Slug + "/"} itemProp="url" className="Campaign w-[100%] h-[100%]">
                    <div className="w-full h-[85%] aspect-[10/7.4]">
                      {/* 画像部分 */}
                      <GatsbyImage className="block w-full h-full rounded-blog overflow-hidden" image={result['Image']}
                        alt={result.AltText ? result.AltText : ""} as={`figure`} style={{ aspectRatio: "25/14" , height: '100%' }}
                      />
                    </div>
                    {/* タイトル部分 */}
                    <div className="w-full text-left h-[20%] px-3 py-2">
                      <p className="CampP md:text-[15px] text-[15px]  mb-4 py-2 text-left " >{shortName}</p>
                      <p className="text-[8px]  text-left pb-2" >{shortNameEx}</p>
                      {/* <p className="text-[8px]  text-left pb-2" ><span>作成日：〇〇〇〇〇〇〇〇</span> <span className="pl-2">更新日：〇〇〇〇〇〇〇〇</span></p> */}
                    </div>
                  </a>
                </article>
              </div>
            </> ;
  });

  // ページネーション設定
  let previous = myPageNum - 1;
  let previousUrl = (previous === 1) ? indexUrl + myUrl : ((previous < 1) ? "" : indexUrl + myUrl  + previous + "/");
  // let current = myPageNum;
  // let currentUrl = (current === 1) ? indexUrl + myUrl : ((current < 1) ? "" : indexUrl + myUrl  + current + "/");
  let next = myPageNum + 1;
  let nextUrl = (next === 1) ? indexUrl + myUrl : ((next > pageContext.allPages) ? "" : indexUrl + myUrl + next + "/");
  
  const fixedItem = FixedFoot( 'nomal', '' ); // 固定フッター

    return (
      <>
        <Layout>
          {seoTag}
          <div className="bg-[#F6F6F6] md:pb-[45px] pb-2">
            <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
              <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
                <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">{ commonTitle }</h2>
                <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">Just tradeのお得なキャンペーン情報をお届けします。</h3>
              </div>
            </div>
          </div>
          
          {/* カテゴリ本文 
          <div className="md:max-w-5xl max-w-xl mx-auto pt-5 px-5 pb-5"> 
            <div>
              <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-[#1F55A6] mt-[70px] ">{ postTitle }</h3>
              <p className="mb-[30px] max-w-[300px] md:max-w-[960px] overflow-hidden">{postText1}</p>
            </div>
          </div>*/}

          {/* 記事一覧 */}
          <div className="mx-auto flex max-w-xl flex-wrap items-center md:max-w-5xl md:justify-start justify-center px-5 mt-[70px]">
            {pickupList}
          </div>

          {/* ページネーション */}
          <div className="flex py-10 text-[11px] text-sm bg-[#F6F6F6]">
            <div className="text-center w-1/2">
              {previousUrl && <a href={previousUrl}>＜ PREV</a>}
            </div>
            <div className="text-center w-1/2">
              {nextUrl && <a href={nextUrl}>NEXT ＞</a>}
            </div> 
          </div>

          {/* 固定フッター */}
          {fixedItem}
        </Layout>
      </>
    )
  }
export default CampaignArchive